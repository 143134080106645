import SERVICE_URL from "../config/API_DATA"
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../config/API_DATA";

export default class AllUser {
    static buildRequest() {
        let returnValue = null;
        try {
            let body = {
                "isactiveusers": true,
                "isInactiveusers": true
            };

            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.GET_All_USER, "error in building body for GetAllUser api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {


        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.GET_All_USER, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.GET_All_USER, "error in getting Response from GetAllUser api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}