<template>
  <div>
    <CCard class="mob-pd-0px">
      <CCardHeader class="grey-header d-flex mob-flex-column ai-center">
        <CRow class="flex-flow-column">
          <CCol>
            <strong>{{ $t("manageUser.title") }}</strong>
          </CCol>
          <p class="desc" v-if="GET_ADMIN_SIGNIN_RES">
            {{ $t("manageUser.admindesc") }}
          </p>
          <p class="desc" v-else>{{ $t("manageUser.desc") }}</p>
        </CRow>
        <CButton
          @click="navigateToAddUser(null)"
          size="sm"
          color="info"
          class="ml-auto mob-w100"
          >{{ $t("manageUser.newUser") }}</CButton
        >
      </CCardHeader>

      <CCardBody class="user-manage-table pt-0px">
        <CRow>
          <CAlert v-if="alertValue" show color="danger">
            <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
              <p v-for="(value, index) in alertValue.valueList" :key="index">
                {{ value }}
              </p>
            </div>
            <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
              <span>{{ $t("cAlert.errorCode") + " : " }}</span
              ><span v-for="(code, index) in alertValue.codeList" :key="index">
                {{ code + " " }}
              </span>
            </div>
            <div v-if="alertValue && alertValue.constructor == String">
              <p>
                {{ alertValue }}
              </p>
            </div>
          </CAlert>
        </CRow>

        <!--          :class="{table_count_visible: !(userCount&&userCount.total) && !(userCount&&userCount.active) && !(userCount.inActive||userCount.inActive==0) }"-->

        <CRow
          class="fullscreen-ex-row"
          :class="{
            table_count_visible:
              (userCount && userCount.total) ||
              (userCount && userCount.active) ||
              (userCount && (userCount.inActive || userCount.inActive == 0)),
          }"
        >
          <CCol
            id="user-manage-table"
            class="usersTable border-grey manage-user-table"
          >
            <cTable
              :fields="lsUserTableFields"
              :items="lsUserTableItems != null ? lsUserTableItems : []"
              caption="User"
              column-filter
              items-per-page-select
              @row-clicked="navigateToUserStatus"
              :items-per-page="manageUsersTableWatchList.itemsPerPage"
              hover
              :sorter="{ resetable: true }"
              :pagination="paginationSettings"
              @update:column-filter-value="saveFilterValue"
              :loading="isLoading"
              class="w-100 d-flex flex-flow-wrap"
              :sorterValue="{ column: 'name', asc: 'true' }"
              @filtered-items-change="getFilteredAgentCount"
              @page-change="onPageChange"
              @pagination-change="onPaginationChange"
              :tableWatchList="manageUsersTableWatchList"
              @getFields="onSaveColumns"
              :manageColumn="true"
              :paginationLenOption="true"
            >
              <!-- <template #over-table>
                         <cManageColumns v-if="lsUserTableFields&&lsUserTableFields.length>0" :lsFields="lsUserTableFields" @getFields="onSaveColumns"/>
                        <cPaginationLenOption :paginationWatchLs="manageUsersTableWatchList"/>
                    </template> -->
              <template #firstname="{ item }">
                <td>{{ item.firstname + " " + item.lastname }}</td>
              </template>

              <template #roles-filter>
                <div>
                  <cDropDown
                    v-model="role"
                    :ctrlName="'roleName'"
                    :isAllowEmpty="false"
                    :isShowCode="true"
                    :isMultiple="false"
                    :isSearchable="true"
                    :url="rolesServiceURL"
                    :selValue="role"
                    :isManageUser="true"
                    :isSystemRole="true"
                    :isB2BRole="true"
                    v-on:input="onRoleChange"
                    class="hide-invalid-feedback"
                  />
                </div>
              </template>
              <template #sub-over-table>
                <div class="table-count-wrap mt-20px-imp min-w-100per">
                  <div class="d-inline-flex">
                    <div class="single-tab" v-if="userCount && userCount.total">
                      <div class="tc-total-wrap">
                        <span>{{ $t("manageUser.totalUsers") }}</span>
                        <span>{{ userCount.total }}</span>
                      </div>
                    </div>

                    <div
                      class="single-tab"
                      v-if="userCount && userCount.active"
                    >
                      <div class="tc-active-wrap">
                        <span>{{ $t("manageUser.active") }} </span>
                        <span>{{ userCount.active }}</span>
                      </div>
                    </div>
                    <div
                      class="single-tab"
                      v-if="
                        userCount &&
                        (userCount.inActive || userCount.inActive == 0)
                      "
                    >
                      <div class="tc-inactive-wrap">
                        <span>{{ $t("manageUser.inactive") }}</span>
                        <span>{{ userCount.inActive }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #status-filter>
                <div>
                  <cDropDown
                    :key="'status'"
                    v-model="status"
                    url="status"
                    :isAllowEmpty="false"
                    :ctrlCode="'value'"
                    :ctrlName="'name'"
                    :isShowCode="false"
                    :isMultiple="false"
                    :isSearchable="true"
                    :selValue="status"
                    v-on:input="onStatusChange"
                    class="hide-invalid-feedback"
                  />
                </div>
              </template>

              <template #status="{ item }">
                <td>
                  <CBadge
                    :color="item.status == 'Active' ? 'success' : 'danger'"
                  >
                    {{ item.status }}
                  </CBadge>
                </td>
              </template>

              <template #no-items-view>
                <div class="text-center my-5">
                  <h2>{{ isLoading ? "" : $t("manageUser.listEmpty") }}</h2>
                </div>
              </template>

              <template #actions-filter>
                <td style="border-top: 0px; height: 100%">
                  <div @click="clearAllFilters()" class="clear-filter-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 373.6 373.6"
                      style="
                        enable-background: new 0 0 373.6 373.6;
                        fill: #1e90ff;
                      "
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M343.7,0H29.9c-5.5,0-10.1,4.9-10.1,10.5v64.2c0,0.3,0,0.6,0.1,1c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.3,0.1,0.5    c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0.1,0.3,0.1,0.4c0.1,0.2,0.1,0.4,0.2,0.5c0,0.1,0.1,0.2,0.1,0.4c0.1,0.2,0.1,0.3,0.2,0.5    c0.1,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4    c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.1,0.2,0.2l114.3,124.6v157.5c0,3.9,2.2,7.4,5.7,9.1c1.3,0.6,2.7,0.9,4.2,0.9    c2.4,0,4.7-0.8,6.5-2.4l80.2-68.3c2.2-1.9,3.5-4.7,3.4-7.6v-89.2L351,81.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.3-0.4    c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.3,0.3-0.4s0.2-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.4    c0.1-0.2,0.2-0.3,0.2-0.5c0-0.1,0.1-0.2,0.1-0.4c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.3,0.1-0.5    s0.1-0.3,0.1-0.5c0-0.1,0.1-0.3,0.1-0.4c0-0.3,0.1-0.6,0.1-1V10.4C353.8,4.9,349.3,0,343.7,0z M219.5,195.6    c-1.7,1.8-2.7,4.1-2.7,6.6v88.4l-60,51.3V202.2c0-2.5-1-4.8-2.7-6.6L52.6,85H321L219.5,195.6z M333.8,65h-294V20h294V65z"
                          />
                        </g>
                      </g>
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                      <g />
                    </svg>
                    {{ $t("manageUser.clearFilter") }}
                  </div>
                </td>
              </template>

              <template #actions="{ item }">
                <td class="py-2 d-flex ai-center">
                  <CButton
                    size="sm"
                    color="success"
                    @click="navigateToUserStatus(item)"
                    class="btn-manage b-radius-3px hover-none"
                  >
                    <span> {{ $t("manageUser.manage") }}</span>
                  </CButton>

                  <!-- <CButton
                                    size="sm"
                                    @click="navigateToAddUser(item)"
                                    class="circle-btn-icon b-radius-3px hover-none"
                            >
                                <span>{{ $t("manageUser.edit") }}</span>
                            </CButton> -->
                  <!-- {{$t('manageUser.edit')}} -->
                </td>
              </template>
            </cTable>
          </CCol>
        </CRow>

        <!-- table filter hidded
        tableFilter-->
        <!-- <button class="mf-success-round-btn">Apply</button> -->
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import cDropDown from "./../../components/form/cDropDown";
import { navigate } from "../../router/index";

import { mapGetters, mapActions } from "vuex";
import {
  PATHNAME,
  COMPONENT_NAME,
  DEFAULT_DROPDOWN_STATUS,
  ORDER,
} from "./../../util/constants";
import apiGetAllUser from "./../../services/apiGetAllUser";
import Logger from "./../../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";

import { VALIDATE_SUCCESS_RES, getErrorMessage } from "../../util/apiValidate";
import {
  handleJWT,
  getErrorAlertValue,
  addClassToBody,
  removeClassBody,
  OrderByList,
} from "../../util/util";
import BaseConfiguration from "../../config/base.js";
import cTable from "./../../components/dataTable/cTable";

export default {
  name: COMPONENT_NAME.MANAGE_USERS,
  components: {
    cDropDown,
    cTable,
  },

  data: () => {
    return {
      userCount: null,
      manageUsersTableWatchList: {
        filteredlistCount: null,
        itemsPerPage: 25,
        currentPageNum: 1,
      },
      rolesServiceURL: SERVICE_URL.GET_USER_ROLES,
      showAlertModal: false,

      user: {
        emailid: "",
        name: "",
      },
      status: {
        name: DEFAULT_DROPDOWN_STATUS.ALL,
        value: null,
      },

      role: {
        roleName: null,
      },
      paginationSettings: {
        align: "end",
      },
      alertValue: null,

      lsUsers: [],
      lsUserTableItems: [],
      lsUserTableFields: [],
      isLoading: true,
      //fullscreen: false
    };
  },

  methods: {
    ...mapActions("sAddUser", ["SET_SELECTED_USER"]),
    ...mapActions("sManageUsers", {
      SET_ALL_USER_RESPONSE: "SET_ALL_USER_RESPONSE",
      SET_USER_COUNT: "SET_USER_COUNT",
      CLEAR_MANAGE_USERS_STORE: "CLEAR_MANAGE_USERS_STORE",
    }),

    ...mapActions("sMain", {
      ON_PROGRESS_START: "ON_PROGRESS_START",
      ON_PROGRESS_FINISH: "ON_PROGRESS_FINISH",
      ON_PROGRESS_FAIL: "ON_PROGRESS_FAIL",
      ON_PROGRESS_SET: "ON_PROGRESS_SET",
    }),
    //   toggle () {
    //         this.$refs['fullscreen'].toggle()
    //         // recommended
    //         // this.fullscreen = !this.fullscreen // deprecated
    //       },
    // fullscreenChange (fullscreen) {
    //         this.fullscreen = fullscreen
    //       },
    onSaveColumns(pFields) {
      try {
        if (BaseConfiguration.isDebug) console.log("onSaveColumns");
        this.lsUserTableFields = pFields;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onSaveColumns",
          err.toString(),
          "onSaveColumns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onPaginationChange(pItemsPerPage) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPaginationChange");
        this.manageUsersTableWatchList.itemsPerPage = pItemsPerPage;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPaginationChange",
          err.toString(),
          "onPaginationChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onPageChange(pPageNum) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPageChange");
        this.manageUsersTableWatchList.currentPageNum = pPageNum;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in onPageChange",
          err.toString(),
          "onPageChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getFilteredAgentCount(pFilteredItems) {
      try {
        if (BaseConfiguration.isDebug) console.log("getFilteredAgentCount");
        this.manageUsersTableWatchList.filteredlistCount =
          pFilteredItems.length;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getFilteredAgentCount",
          err.toString(),
          "getFilteredAgentCount",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    clearAllFilters() {
      try {
        if (BaseConfiguration.isDebug) console.log("clearAllFilters");

        this.role = { roleName: DEFAULT_DROPDOWN_STATUS.ALL };
        this.status = {
          name: DEFAULT_DROPDOWN_STATUS.ALL,
          value: null,
        };

        if (this.user) {
          Object.keys(this.user).forEach((key) => {
            this.user[key] = "";
          });
        }
        // this.user.emailid = "";
        // this.user.name = "";
        // this.user.agencyName="";

        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in clearAllFilters",
          err.toString(),
          "clearAllFilters",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    saveFilterValue(pUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("saveFilterValue");
        if (pUserObj) {
          this.user = pUserObj;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in saveFilterValue",
          err.toString(),
          "saveFilterValue",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getUserFields() {
      let returnValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getUserFields");

        let lsFields = [
          {
            key: "name",
            // _style: "width:30%",

            label: this.$t("manageUser.name"),
            isLocked: true,
          },

          {
            key: "emailid",
            // _style: "width:30px;",
            label: this.$t("manageUser.emailId"),
            isLocked: false,
          },

          {
            key: "roles",
            label: this.$t("manageUser.role"),
            custom_filter: "customFilter",
            // _style: "width:30px",
            sorter: false,
            filter: false,
            isLocked: true,
          },

          {
            key: "status",
            label: this.$t("manageUser.status"),
            // _style: "width:20px",
            sorter: false,
            filter: false,
            isLocked: true,
          },

          {
            key: "actions",
            label: this.$t("manageUser.actions"),
            // _style: "width:20px",

            sorter: false,
            filter: false,
            isLocked: true,
          },
        ];
        returnValue = lsFields;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in getUserFields",
          err.toString(),
          "getUserFields",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    showAlert(value) {
      this.alertValue = value;
    },

    onRoleChange(pRole) {
      try {
        if (BaseConfiguration.isDebug) console.log("onRoleChange");

        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
        if (
          this.lsUserTableItems != null &&
          this.lsUserTableItems.length > 0 &&
          pRole
        ) {
          let status = this.status;
          this.lsUserTableItems = this.lsUserTableItems.filter(function (user) {
            if (
              user.roles == pRole.roleName &&
              (user.status == status.name ||
                status.name == DEFAULT_DROPDOWN_STATUS.ALL)
            ) {
              return true;
            } else if (
              pRole.roleName == DEFAULT_DROPDOWN_STATUS.ALL &&
              user.status == status.name
            ) {
              return true;
            } else if (
              pRole.roleName == DEFAULT_DROPDOWN_STATUS.ALL &&
              status.name == DEFAULT_DROPDOWN_STATUS.ALL
            ) {
              return true;
            } else {
              return false;
            }
          });
        }
        //  this.resetUserStatus();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Role Change",
          err.toString(),
          "onRoleChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    onStatusChange(pStatus) {
      try {
        if (BaseConfiguration.isDebug) console.log("onStatusChange");

        this.lsUserTableItems = JSON.parse(JSON.stringify(this.lsUsers));
        if (
          this.lsUserTableItems != null &&
          this.lsUserTableItems.length > 0 &&
          pStatus
        ) {
          let role = this.role;
          this.lsUserTableItems = this.lsUserTableItems.filter(function (user) {
            if (
              user.status == pStatus.name &&
              (user.roles == role.roleName ||
                role.roleName == DEFAULT_DROPDOWN_STATUS.ALL)
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              user.roles == role.roleName
            ) {
              return true;
            } else if (
              pStatus.name == DEFAULT_DROPDOWN_STATUS.ALL &&
              role.roleName == DEFAULT_DROPDOWN_STATUS.ALL
            ) {
              return true;
            } else {
              return false;
            }
          });
        }

        // this.resetUserRole();
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in on Status Change",
          err.toString(),
          "onStatusChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    setSelectedUserObj(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("setSelectedUserObj");

        if (
          pSelectedUserObj != null &&
          this.allUserResponse &&
          this.allUserResponse.length
        ) {
          pSelectedUserObj = this.allUserResponse.find(function (user) {
            return user.userid == pSelectedUserObj.userid;
          });
          pSelectedUserObj = JSON.parse(JSON.stringify(pSelectedUserObj));
          this.SET_SELECTED_USER(pSelectedUserObj);
        } else {
          this.SET_SELECTED_USER(null);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in selecting User obj",
          err.toString(),
          "setSelectedUserObj",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    navigateToAddUser(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToAddUser");

        this.setSelectedUserObj(pSelectedUserObj);

        PATHNAME.ADD_USER;
        navigate(PATHNAME.ADD_USER);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in navigate To User",
          err.toString(),
          "navigateToUser",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    navigateToUserStatus(pSelectedUserObj) {
      try {
        if (BaseConfiguration.isDebug) console.log("navigateToUserStatus");

        this.setSelectedUserObj(pSelectedUserObj);

        navigate(PATHNAME.USER_STATUS);
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in navigate To User Status",
          err.toString(),
          "navigateToUserStatus",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    async getAllUsers() {
      let returnValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getAllUsers");

        let allUserRequest = apiGetAllUser.buildRequest();

        if (allUserRequest != null) {
          let allUserResponse = await apiGetAllUser.getResponse(allUserRequest);
          if (VALIDATE_SUCCESS_RES(allUserResponse)) {
            returnValue = allUserResponse.data.data.userList;
            if (allUserResponse.data.data && allUserResponse.data.data.count) {
              this.userCount = allUserResponse.data.data.count;
              this.SET_USER_COUNT(this.userCount);
            }
            this.showAlert(null);
            this.SET_ALL_USER_RESPONSE(returnValue);
          } else {
            let error = getErrorMessage(allUserResponse);

            let isJwt = handleJWT(error);
            if (!isJwt) {
              this.showAlert(getErrorAlertValue(error));
            }
          }
        } else {
          throw "invalid request";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in  get All Users",
          err.toString(),
          "getAllUsers",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return returnValue;
    },

    getUsersTableItems(pAllUserResponse) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getUsersTableItems");

        if (pAllUserResponse && pAllUserResponse.length > 0) {
          pAllUserResponse.forEach((value) => {
            this.lsUsers.push({
              name: value.firstname + " " + value.lastname,
              emailid: value.emailid,
              roles: value.roles[0],
              status: value.isactive ? "Active" : "Inactive",
              userid: value.userid,
              agencyName: value.agencyName,
            });
          });
        } else {
          this.lsUsers = [];
        }
        retValue = JSON.parse(JSON.stringify(this.lsUsers));
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MANAGE_USERS,
          "error in  getUsersTableItems",
          err.toString(),
          "getUsersTableItems",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }

      return retValue;
    },
    getPropToSortUsers(pUser) {
      try {
        if (BaseConfiguration.isDebug) console.log("getPropToSortUsers");
        if (pUser.firstname) {
          return pUser.firstname.toLowerCase();
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.MANAGE_USERS,
          "error in getPropToSortUsers",
          err.toString(),
          "getPropToSortUsers",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },

  async created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");

      let listUsers = [];
      // if (this.allUserResponse != null && this.allUserResponse.length > 0) {
      //   listUsers = this.allUserResponse;
      // } else {
      listUsers = await this.getAllUsers();
      // }
      listUsers = OrderByList(listUsers, this.getPropToSortUsers, ORDER.ASC);

      if (listUsers)
        this.lsUserTableItems = this.getUsersTableItems(
          JSON.parse(JSON.stringify(listUsers))
        );

      this.lsUserTableFields = this.getUserFields();
      if (this.GET_USER_COUNT) {
        this.userCount = this.GET_USER_COUNT;
      }
      this.isLoading = false;
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.MANAGE_USERS,
        "error in  Created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
  computed: {
    ...mapGetters("sManageUsers", {
      allUserResponse: "GET_ALL_USER_RESPONSE",
      GET_USER_COUNT: "GET_USER_COUNT",
    }),
    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
  },
  beforeCreate() {
    addClassToBody(COMPONENT_NAME.MANAGE_USERS);
  },

  beforeDestroy() {
    removeClassBody();
  },
};
</script>
